import { Card, Divider, Row, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import currencyFormatter from "../../../../utils/NumberFormater";
import { getRequest } from "../../../../utils/handler/apiHandler";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

const CreditAssessmentFormDetails = () => {
    const prospectId = useLocation().pathname.split("/").pop();
    const [data, setData] = useState<any>(null);
    useEffect(() => {
        try {
            getRequest(`${process.env.REACT_APP_CONTRACT_SERVICE_ENDPOINT}/contractservice/credit/prospectId?id=${prospectId}`).then(res => {
                setData(res.data);
            });
        } catch (err) {

        }
    }, []);

    const convertInstantToISO = (date: any) => {
        const isoDate = new Date(date).toISOString()
        return isoDate;
    }

    return ( 
        <React.Fragment>
        {
            data && <>
            <span style={{display: "flex", alignItems: "center"}}>
            <Typography style={{fontSize: 14, fontWeight: 500}}>Request Details</Typography>
            {/* <Tag style={{marginLeft: 10, fontSize: 12, fontWeight: 500, color: data?.creditRequestStatusMaster?.color, backgroundColor: data?.creditRequestStatusMaster?.backgroundColor, border: "none"}}>{data.creditRequestStatusMaster?.name}</Tag> */}
            </span>
            <Row style={{width: "100%", marginTop: 20}}>
                <Card className="credit-description">
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Legal Business Name</span>} data={data?.legalBusinessName?.name} bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Type</span>} data={data?.type} bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Current Year Potential</span>} data={data?.currentYearFleetRequirement} bgColor="#CEE6FA" border={false} />
                </Card>
                <Card className="credit-description">
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Business Segment</span>} data={data?.segment?.name} bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Commercial Meeting</span>} data={data?.isCommercialMeetingHeld ? "Held" : "Not Held"} bgColor="#CEE6FA" border={false} />
                </Card>
                <Card className="credit-description">
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Application Date</span>} data={dayjs(convertInstantToISO(data.createdAt)).format("YYYY-MM-DD")} bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Total Investment Value</span>} data={currencyFormatter(data.totalInvestmentValue)} bgColor="#CEE6FA" border={false} />
                </Card>
            </Row>
            <Row style={{width: "100%"}}>
                {
                    data.entityDescription && <Card className="credit-description" style={{width: "49%", padding: 20}}>
                    <Typography style={{fontWeight: 500}}>Entity Description</Typography>
                    <Typography>{data?.entityDescription}</Typography>
                </Card>
                }
                {
                    data.remark && <Card className="credit-description" style={{width: "48%", padding: 20}}>
                    <Typography style={{fontWeight: 500}}>Additional Remarks</Typography>
                    <Typography>{data?.remark}</Typography>
                </Card>
                }
                
            </Row>
            <Typography style={{fontSize: 14, fontWeight: 500, marginBottom: 10}}>Requirement Details</Typography>
            <Row style={{width: "100%", flexWrap: "nowrap", overflow: "scroll"}}>
                {/* Iterate over all requirements */}
                {
                    data?.creditRequestRequirements && data?.creditRequestRequirements.map((requirement: any) => {
                        return <RequirementCard data={requirement}/>
                    })
                }
            </Row>
            </>
        }
        </React.Fragment>
     );
}

export default CreditAssessmentFormDetails;


interface DescriptionItemProps {
    label: any;
    data: any;
    bgColor: string
    border: boolean;
}
const DescriptionItem: React.FC<DescriptionItemProps> = ({ label, data, bgColor, border }) => {
    return <>
    <div className="" style={{display: "flex", width: "100%"}}>
        <div style={{backgroundColor: bgColor, width: "50%", padding: 10}}>{label}</div>
        <div style={{padding: 10, width: "50%", borderBottom: border ? "1px #F0F0F0 solid" : "none"}}>{data}</div>
    </div>
    </>
}

interface RequirementCardProps {
    data: any
}
const RequirementCard: React.FC<RequirementCardProps> = ({ data }) => {
    return <>
        <Card className="credit-description" style={{minWidth: 400, padding: 20}}>
            <Typography style={{fontSize: 14}}><span style={{fontWeight: 500}}>Vehicle Category:  </span>{data && JSON.parse(data.vehicleCategory).name}</Typography>
            <Typography style={{fontSize: 12}}><span style={{fontWeight: 500}}>Total value:  </span>{currencyFormatter(data.totalValue)}</Typography>
            <Divider style={{margin: "10px 0"}} />
            <div style={{display: "flex", flexWrap: "wrap", width: "100%"}}>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Tenure</Typography>
                    <Typography style={{fontSize: 14}}>{JSON.parse(data?.leaseTerm)?.inMonth} Month</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12,  fontWeight: 500}}>No. Of Vehicle</Typography>
                    <Typography style={{fontSize: 14}}>{data.noOfVehicles}</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Interest Rate Offered</Typography>
                    <Typography style={{fontSize: 14}}>{data.interestRateOffered}%</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Investment Value per Vehicle</Typography>
                    <Typography style={{fontSize: 14}}>{currencyFormatter(~~data.investmentValuePerVehicle)}</Typography>
                </div>
            </div>
        </Card>
    </>
}