import { useEffect, useState } from "react";
import { Form, Input, Button, message, Row, Col } from "antd";
import useMenuEffects from "./useMenuEffects";

const AddMenu = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { menuData, setMenuData } = useMenuEffects(setLoading);

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/addParentMenu`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          menuName: values.menu,
          menuOrder: values.menuOrder,
          url: values.url,
          icon: values.icon,
        }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to add menu');
      }

      message.success('Menu added successfully');
      setTimeout(() => 
      window.location.reload(), 
        3000);
    } catch (error:any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        style={{ margin: '20px' }}
      >
        <Row gutter={16}>
          <Col span={11}>
            <Form.Item label="Menu" name="menu" rules={[{ required: true, message: 'Please input the menu name!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Icon" name="icon" rules={[{ required: false, message: 'Please input the icon!' }]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={11}>
            <Form.Item label="URL" name="url" rules={[{ required: false, message: 'Please input the URL!' }]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="Menu Order" name="menuOrder" rules={[{ required: false, message: 'Please input the menu order!' }]}>
              <Input type="number" />
            </Form.Item>
          </Col>
        </Row>
        
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Add Menu
          </Button>
        </Form.Item>
      </Form>

      {/* {menuData?.map((menu: any, index: number) => (
        <h3 style={{
          margin: '10px 10px',
          backgroundColor: "#A7C6ED",
          height: '2.5rem',
          padding: '10px 20px',
          borderRadius: '5px'
        }} key={index}>{menu?.menuName}</h3>
      ))} */}
    </>
  );
};

export default AddMenu;
