import React, { useEffect, useState } from "react";
import { Card, Col, Descriptions, Divider, Row, Typography } from "antd";
import currencyFormatter from "../../../../utils/NumberFormater";
import _ from "lodash";
import dayjs from "dayjs";

interface CreditRequestPreviewProps {
    form: any,
    data: any,
    addParent: boolean;
    setAddParent: (addParent: boolean) => void;
}
const CreditRequestPreview: React.FC<CreditRequestPreviewProps> = ({ form, data, addParent, setAddParent }) => {
    const [previewData, setPreviewData] = useState<any>({});
    
    // Form data is feed to this component
    let sum = 0;
    if(form) {
        !_.isEmpty(form.getFieldsValue(true)) && form.getFieldsValue(true).requirementDetails.map((e: any) => {
            sum = sum + (e.investmentPerVehicle*e.noOfVehicle)
        })
    }
    const convertInstantToISO = (date: any) => {
        const isoDate = new Date(date).toISOString()
        return isoDate;
    }
    useEffect(() => {
        if(!_.isNull(form)) {
            const formData = form.getFieldsValue(true);
            const newDate = dayjs(convertInstantToISO(new Date())).format("YYYY-MM-DD");

            setPreviewData({
                legalBusinessName: formData.legalBusinessName,
                businessSegment: formData.businessSegment === "Other" ? formData.otherBusinessSegment: formData.businessSegment,
                currentYearFleetRequirement: formData.currentYearFleetRequirement,
                commercialPhysicalMeeting: formData.commercialPhysicalMeeting,
                date: newDate,
                requirementDetails: formData.requirementDetails,
                entityDescription: formData.entityDescription,
                addRemark: formData.addRemark,
                totalValue: sum,
            });
        }
    }, []);

    return ( 
        <React.Fragment>
            <Row style={{width: "100%", marginTop: 20}}>
                <Card className="credit-description">
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Legal Business Name an</span>} data={previewData?.legalBusinessName} bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Type</span>} data="Prospect" bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Current Year Potential</span>} data={previewData?.currentYearFleetRequirement} bgColor="#CEE6FA" border={false} />
                </Card>
                <Card className="credit-description">
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Business Segment</span>} data={previewData?.businessSegment} bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Commercial Meeting</span>} data={previewData?.commercialPhysicalMeeting ? "Held" : "Not Held"} bgColor="#CEE6FA" border={false} />
                </Card>
                <Card className="credit-description">
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Application Date</span>} data={previewData?.date} bgColor="#CEE6FA" border={true} />
                    <DescriptionItem label={<span style={{fontWeight: 500}}>Total Investment Value</span>} data={currencyFormatter(sum)} bgColor="#CEE6FA" border={false} />
                </Card>
            </Row>
            <Row style={{width: "100%", marginTop: 10}}>
                {
                    previewData.entityDescription && <Card className="credit-description" style={{width: "49%", padding: 20}}>
                    <Typography style={{fontWeight: 500}}>Entity Description</Typography>
                    <Typography>{previewData?.entityDescription}</Typography>
                </Card>
                }
                {
                    previewData.addRemark && <Card className="credit-description" style={{width: "48%", padding: 20}}>
                    <Typography style={{fontWeight: 500}}>Additional Remarks</Typography>
                    <Typography>{previewData?.addRemark}</Typography>
                </Card>
                }
                
            </Row>
            <Col>
                <Typography style={{fontWeight: 500, marginTop: 10, marginBottom: 10}}>Requirement Details</Typography>
                <Row style={{width: "100%", flexWrap: "nowrap", overflow: "scroll"}}>
                    {
                        previewData?.requirementDetails && previewData?.requirementDetails.map((requirement: any) => {
                            return <RequirementCard data={requirement}/>
                        })
                    }
                </Row>
            </Col>
        </React.Fragment>
     );
}

export default CreditRequestPreview;

interface DescriptionItemProps {
    label: any;
    data: any;
    bgColor: string
    border: boolean;
}
const DescriptionItem: React.FC<DescriptionItemProps> = ({ label, data, bgColor, border }) => {
    return <>
    <div className="" style={{display: "flex", width: "100%"}}>
        <div style={{backgroundColor: bgColor, width: "50%", padding: 10}}>{label}</div>
        <div style={{padding: 10, width: "50%", borderBottom: border ? "1px #F0F0F0 solid" : "none"}}>{data}</div>
    </div>
    </>
}

interface RequirementCardProps {
    data: any
}
const RequirementCard: React.FC<RequirementCardProps> = ({ data }) => {
    console.log(data);

    return <>
        <Card className="credit-description" style={{minWidth: 400, padding: 20}}>
            <Typography style={{fontSize: 14}}><span style={{fontWeight: 500}}>Vehicle Category:  </span>{data.vehicleCategory}</Typography>
            <Typography style={{fontSize: 12}}><span style={{fontWeight: 500}}>Total value:  </span>{currencyFormatter(~~data.investmentPerVehicle*~~data.noOfVehicle)}</Typography>
            <Divider style={{margin: "10px 0"}} />
            <div style={{display: "flex", flexWrap: "wrap", width: "100%"}}>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Tenure</Typography>
                    <Typography style={{fontSize: 14}}>{data.leaseTerm} Month</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12,  fontWeight: 500}}>No. Of Vehicle</Typography>
                    <Typography style={{fontSize: 14}}>{data.noOfVehicle}</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Interest Rate Offered</Typography>
                    <Typography style={{fontSize: 14}}>{data.interestRateOffered}%</Typography>
                </div>
                <div style={{minWidth: "50%"}}>
                    <Typography style={{fontSize: 12, fontWeight: 500}}>Investment Value per Vehicle</Typography>
                    <Typography style={{fontSize: 14}}>{currencyFormatter(~~data.investmentPerVehicle)}</Typography>
                </div>
            </div>
        </Card>
    </>
}