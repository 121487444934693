import { useState } from "react";
import useSubmenuEffects from "./useSubmenuEffects";
import { Button, Col, Form, Input, Row, message } from "antd";
import MenuSelect from "./MenuSelect";

const AddSubmenu = ()=>{
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
  
  const { menuData, setMenuData, submenuData } = useSubmenuEffects(setLoading);
  const handleMenuChange = (parentId: string) => {
    setSelectedMenu(parentId);
    // fetchSubmenuData(parentId);
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_IAM_SERVICE_ENDPOINT}/management/menus/addSubMenu`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          parentMenuId: selectedMenu,
          subMenus: [{  
            menuName: values.menuname,
            menuOrder: values.menuOrder,
            url: values.url,
            icon: values.icon,
            }]
        }),
      });
      
      if (!response.ok) {
        throw new Error('Failed to add menu');
      }

      message.success('Submenu added successfully');
      setTimeout(() => 
      window.location.reload(), 
        3000);
    } catch (error:any) {
      message.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  console.log(`selectedMenu=%o`,selectedMenu)

    return (
        <div style={{margin:'20px'}}>
            <Form>
                <MenuSelect
                    menus={menuData}
                    selectedMenu={selectedMenu}
                    onMenuChange={handleMenuChange}
                />
            </Form>
            {
                selectedMenu && 
                <Form
                    layout="vertical"
                    onFinish={onFinish}
                    style={{ margin: '20px' }}
                >
                    <Row gutter={16}>
                    <Col span={11}>
                        <Form.Item label="Submenu Name" name="menuname" rules={[{ required: true, message: 'Please input the Submenu name!' }]}>
                        <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Icon" name="icon" rules={[{ required: false, message: 'Please input the icon!' }]}>
                        <Input />
                        </Form.Item>
                    </Col>
                    </Row>
                    <Row gutter={16}>
                    <Col span={11}>
                        <Form.Item label="URL" name="url" rules={[{ required: false, message: 'Please input the URL!' }]}>
                        <Input />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <Form.Item label="Submenu Order" name="menuOrder" rules={[{ required: true, message: 'Please input the submenu order!' }]}>
                        <Input type="number" />
                        </Form.Item>
                    </Col>
                    </Row>
                    
                    <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        Add Submenu
                    </Button>
                    </Form.Item>
                </Form>
            }
            
        </div>
        
    );
}
export default AddSubmenu;